import { applyVoucher } from '@mindfulchefuk/actions/basketActions'
import { isAuthenticated } from '@mindfulchefuk/helpers/js/authentication'
import { RootState } from '@mindfulchefuk/types/store'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const useApplyVoucher = (voucherCode: string) => {
  const rehydrated = useSelector(
    // eslint-disable-next-line no-underscore-dangle
    (state: RootState) => state.basket._persist.rehydrated
  )

  const savedVoucherCode = useSelector(
    (state: RootState) => state.basket.savedVoucherCode
  )

  const selectedDelivery = useSelector(
    (state: RootState) => state.basket.selectedDeliveryDate
  )

  const defaultVoucherCode =
    process.env.NEXT_PUBLIC_PROMO_NEW_CUSTOMER_DEFAULT_VOUCHER_CODE
  const isLoggedIn = isAuthenticated()
  const dispatch = useDispatch()

  const voucherToUse =
    isLoggedIn || !rehydrated || !selectedDelivery
      ? undefined
      : voucherCode || savedVoucherCode || defaultVoucherCode

  useEffect(() => {
    if (voucherToUse) {
      dispatch(applyVoucher(voucherToUse, true))
    }
  }, [dispatch, voucherToUse])
}
