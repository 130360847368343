import { useEffect } from 'react'
import { REFERRER_PATH_KEY } from '@mindfulchefuk/hooks/useReferrer'
import { analyticsEvents, PageSource } from '@mindfulchefuk/analytics'
import { instrument } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'

export const useInstrumentChooseRecipesViewed = () => {
  useEffect(() => {
    let sanitisedPageSource = 'other'
    const pageSource = window.sessionStorage.getItem(REFERRER_PATH_KEY) || ''

    // NOTE: Next.js reverts to home page for paths it can't match and there is no additional directory lookup
    const isHomepageSource =
      /^\/$/i.test(pageSource) || !pageSource.substring(1).includes('/')

    if (isHomepageSource) {
      sanitisedPageSource = 'homepage'
    } else if (pageSource.includes('/checkout/about-you')) {
      sanitisedPageSource = 'about you page'
    } else if (pageSource.includes('/checkout/shipping')) {
      sanitisedPageSource = 'shipping page'
    } else if (pageSource.includes('/checkout/payment')) {
      sanitisedPageSource = 'payment page'
    }

    const { name, data } = analyticsEvents.chooseRecipesViewed(
      sanitisedPageSource as PageSource
    )

    instrument(name, { rawPageSource: pageSource, ...data })
  }, [])
}
