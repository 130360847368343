export type Device = 'iOS' | 'Android' | 'unknown'

export const detectDevice = (): Device => {
  const userAgent = navigator.userAgent || navigator.vendor

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return 'iOS'
  }

  return 'unknown'
}
