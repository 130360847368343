import { useCallback, useEffect } from 'react'
import { analyticsEvents } from '@mindfulchefuk/analytics'
import { instrument } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'
import { REFERRER_PATH_KEY } from '@mindfulchefuk/hooks/useReferrer'
import { isBBCGoodFoodSession } from '@mindfulchefuk/features/Homepage/utils/isBBCGoodFoodSession'

type HomepageViewedParams = {
  delay: boolean
}

export const useInstrumentHomepageViewed = (
  { delay }: HomepageViewedParams = {
    delay: false,
  }
) => {
  const homepageViewedEventHandler = useCallback(() => {
    const pageSource = window.sessionStorage.getItem(REFERRER_PATH_KEY)
    const { name, data } = analyticsEvents.homepageViewed(
      isBBCGoodFoodSession() ? 'BBCGF landing page' : null
    )

    instrument(name, { rawPageSource: pageSource, ...data })
  }, [])

  useEffect(() => {
    if (!delay) return homepageViewedEventHandler()

    const timeout = setTimeout(() => homepageViewedEventHandler(), 1000)

    return () => clearTimeout(timeout)
  }, [delay, homepageViewedEventHandler])
}
