// used to display the correct rounding of macro values

const fatSugarProtein = (quantity: number) => {
  if (quantity >= 10) {
    return Math.round(quantity)
  } else if (quantity > 0.5) {
    return Math.round(quantity * 10) / 10
  } else {
    return 0
  }
}

const saturatedFatFibre = (quantity: number) => {
  if (quantity >= 10) {
    return Math.round(quantity)
  } else if (quantity > 0.1) {
    return Math.round(quantity * 10) / 10
  } else {
    return 0
  }
}

const salt = (quantity: number) => {
  if (quantity >= 1) {
    return Math.round(quantity * 10) / 10
  } else if (quantity > 0.0125) {
    return Math.round(quantity * 100) / 100
  } else {
    return 0
  }
}

export const macroRounding = {
  energy: Math.round,
  fat: fatSugarProtein,
  sugar: fatSugarProtein,
  protein: fatSugarProtein,
  saturatedFat: saturatedFatFibre,
  fibre: saturatedFatFibre,
  salt,
}
