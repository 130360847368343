import {
  MEAL_PLAN_BALANCED,
  MEAL_PLAN_PROTEIN_PACKED,
  MEAL_PLAN_PESCETARIAN,
  MEAL_PLAN_PLANT_BASED,
  PROTEIN_BEEF,
  PROTEIN_FISH,
  PROTEIN_LAMB,
  PROTEIN_PORK,
  PROTEIN_POULTRY,
  PROTEIN_SHELLFISH,
  PROTEIN_VEGAN,
  PROTEIN_DAIRY,
} from '@mindfulchefuk/constants'

export type foodGroup =
  | typeof PROTEIN_BEEF
  | typeof PROTEIN_FISH
  | typeof PROTEIN_LAMB
  | typeof PROTEIN_PORK
  | typeof PROTEIN_POULTRY
  | typeof PROTEIN_SHELLFISH
  | typeof PROTEIN_VEGAN
  | typeof PROTEIN_DAIRY

// All types should be in PascalCase but foodGroup is used in a lot of places, so duplicating here
export type FoodGroup = foodGroup

export type mealPlan =
  | typeof MEAL_PLAN_BALANCED
  | typeof MEAL_PLAN_PROTEIN_PACKED
  | typeof MEAL_PLAN_PESCETARIAN
  | typeof MEAL_PLAN_PLANT_BASED

// All types should be in PascalCase but mealPlan is used in a lot of places, so duplicating here
export type MealPlan = mealPlan
interface mealPlanConfig {
  title: string
  value: mealPlan
  label: string
  foodGroups: foodGroup[]
  excludedFoodGroups: foodGroup[]
}

export default [
  {
    title: 'All healthy recipes',
    value: MEAL_PLAN_BALANCED,
    label: 'All healthy recipes',
    foodGroups: [
      PROTEIN_POULTRY,
      PROTEIN_BEEF,
      PROTEIN_PORK,
      PROTEIN_LAMB,
      PROTEIN_FISH,
      PROTEIN_SHELLFISH,
      PROTEIN_VEGAN,
      PROTEIN_DAIRY,
    ],
    excludedFoodGroups: [],
  },
  {
    title: 'Protein Packed',
    value: MEAL_PLAN_PROTEIN_PACKED,
    label: 'High-quality meat and fish recipes only',
    foodGroups: [
      PROTEIN_POULTRY,
      PROTEIN_BEEF,
      PROTEIN_PORK,
      PROTEIN_FISH,
      PROTEIN_SHELLFISH,
      PROTEIN_LAMB,
    ],
    excludedFoodGroups: [PROTEIN_VEGAN, PROTEIN_DAIRY],
  },
  {
    title: 'Pescetarian',
    value: MEAL_PLAN_PESCETARIAN,
    label: 'Pescetarian recipes only',
    foodGroups: [PROTEIN_FISH, PROTEIN_SHELLFISH, PROTEIN_VEGAN, PROTEIN_DAIRY],
    excludedFoodGroups: [
      PROTEIN_POULTRY,
      PROTEIN_BEEF,
      PROTEIN_PORK,
      PROTEIN_LAMB,
    ],
  },
  {
    title: 'Vegan',
    value: MEAL_PLAN_PLANT_BASED,
    label: 'Healthy vegan recipes only',
    foodGroups: [PROTEIN_VEGAN],
    excludedFoodGroups: [
      PROTEIN_POULTRY,
      PROTEIN_BEEF,
      PROTEIN_PORK,
      PROTEIN_LAMB,
      PROTEIN_FISH,
      PROTEIN_SHELLFISH,
      PROTEIN_DAIRY,
    ],
  },
] as mealPlanConfig[]
