import React, { useRef, useEffect, ReactElement } from 'react'
import { Box } from '@mindfulchefuk/design-system'
import { useHasClientHydrated } from '@mindfulchefuk/hooks/useHasClientHydrated'
import { useTrustPilotClickEvent } from '@mindfulchefuk/features/Homepage/hooks/useTrustPilotClickEvent'

type TTrustPilotMicroCombo = {
  id?: string
}

export const TrustPilotMicroCombo = ({
  id,
}: TTrustPilotMicroCombo): ReactElement => {
  const isClient = useHasClientHydrated()
  const ref = useRef(null)

  // If window.Trustpilot is available it means that we need to load the
  // TrustPilotSlider from our ref. If it's not, it means the script you pasted
  // into <head /> isn't loaded just yet. When it is, it will automatically load
  // the TrustPilotSlider.
  useEffect(() => {
    if (isClient && window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [isClient])

  useTrustPilotClickEvent({
    widgetName: 'microcombo',
    eventAction: 'Trust Pilot Clicked',
    eventLabel: 'Rating clicked',
  })

  const mediaQueries = `
  @media only screen and (min-width: 525px) {
   .trustpilot-widget {
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     justify-content: center;
   }
  }
`
  return isClient ? (
    <Box px={24} my={16} id={`${id}-container`}>
      <Box
        ref={ref}
        data-widget-name="microcombo"
        className="trustpilot-widget"
        data-locale="en-GB"
        data-template-id="5419b6ffb0d04a076446a9af"
        data-businessunit-id="563ca4940000ff000585374b"
        data-style-height="20px"
        data-style-width="100%"
        data-theme="light"
      />
      <style>{mediaQueries}</style>
    </Box>
  ) : null
}
