import { repopulateBasket } from '@mindfulchefuk/actions/basketActions'
import usePrevious from '@mindfulchefuk/hooks/usePrevious'
import { RootState } from '@mindfulchefuk/types/store'
import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

// Runs a side-effect that repopulates the basket  when state.recipes.allRecipes changes
export const useRepopulateBasketOnFilter = () => {
  const recipeIds = useSelector(
    (state: RootState) => state.recipes.allRecipes.map((recipe) => recipe.id),
    shallowEqual
  )

  const previousRecipeIds = usePrevious(recipeIds)
  const dispatch = useDispatch()

  useEffect(() => {
    // Only run this effect when _changing_ recipes, not on initial load
    if (previousRecipeIds?.length && previousRecipeIds !== recipeIds) {
      dispatch(repopulateBasket())
    }
  }, [previousRecipeIds, recipeIds, dispatch])
}
