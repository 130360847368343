import React, { ReactElement } from 'react'

import { Box, Flex, Svg, Text } from '@mindfulchefuk/design-system'

import { BBCGoodFoodLogo } from '@mindfulchefuk/features/Homepage/components/BBCGoodFood/BBCGoodFoodLogo'

export const BBCGoodFoodWelcome = (): ReactElement => {
  return (
    <>
      <Box display="flex" marginBottom="-1px" maxHeight={48}>
        <WelcomeWaveTop />
      </Box>
      <Box
        backgroundColor="bbc-good-food-gray"
        width="100%"
        py={24}
        px={{ base: 24, sm: 48, md: 80 }}
      >
        <Flex
          maxWidth="1024px"
          margin="0 auto"
          direction={{ base: 'column', md: 'row' }}
          alignItems="center"
        >
          <Box
            width={{ base: 144, sm: 240 }}
            minWidth={{ sm: 240 }}
            mr={{ md: 64 }}
          >
            <BBCGoodFoodLogo color="bbc-good-food-black" />
          </Box>
          <Box textAlign="center">
            <Text
              as="h1"
              variant="heading-md"
              color="bbc-good-food-pink"
              my={16}
            >
              Welcome to the Good Food Partnership
            </Text>
            <Text as="p" variant="heading-xs" color="aubergine" mb={12}>
              Powered by Mindful Chef
            </Text>
            <Text as="p" variant="body-lg" color="bbc-good-food-black">
              Each week we&lsquo;ll be bringing you a curated selection of
              healthy recipes chosen by{' '}
              <Text as="strong" color="bbc-good-food-pink">
                Good Food
              </Text>
              , which are sourced & delivered by
              <Text as="strong" color="aubergine">
                {' '}
                Mindful Chef.
              </Text>
              <br />
              <br />
              Discover new mouth-watering recipes that combine delicious
              seasonal flavours with only the best in local British produce.
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box display="flex" marginTop="-1px" maxHeight={48} mb={32}>
        <WelcomeWaveBottom />
      </Box>
    </>
  )
}

const WelcomeWaveTop = () => (
  <Svg
    width="100%"
    viewBox="0 0 2301 80"
    preserveAspectRatio="none"
    color="bbc-good-food-gray"
  >
    <path
      d="M0.5 9.35964L548.547 0L820.324 7.48771L970.813 9.35964L1283.02 7.48771L1621.28 2.80789L2020.86 0H2300.5V80H2225.15H1966.63H1433.96H1005.1H0.5V9.35964Z"
      fill="currentColor"
    />
  </Svg>
)

const WelcomeWaveBottom = () => (
  <Svg
    width="100%"
    viewBox="0 0 2300 80"
    preserveAspectRatio="none"
    color="bbc-good-food-gray"
  >
    <path
      d="M0 0H548.047H1076.44H1356.32H1741.15H1983.35H2169.93H2300V73.7511L1911.87 75.0127L1502.19 72.4229L966.719 73.7511L524.688 80L0.000143721 69.2581L0 0Z"
      fill="currentColor"
    />
  </Svg>
)
