import React, { ReactElement } from 'react'
import NextLink from 'next/link'
import styled from '@emotion/styled'

import {
  Box,
  Image,
  minWidths,
  ZIndexToken,
} from '@mindfulchefuk/design-system'

import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'
import { CTALocation } from '@mindfulchefuk/analytics'
import { logEvent } from '@mindfulchefuk/utils/analytics'
import {
  getPortionLabelConfig,
  layers,
} from '@mindfulchefuk/features/Homepage/components/Hero/config'
import { CMSImage } from '@mindfulchefuk/features/CMS/components'
import { isBBCGoodFoodSession } from '@mindfulchefuk/features/Homepage/utils/isBBCGoodFoodSession'
import { trackAnalyticsEvent } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'

const PlateBox = styled(Box)`
  transition: transform ease-in-out 0.1s;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    transform: scale(1.05);
  }
`

type TPlate = {
  altText: string
  href: string
  imageUrl: string
  layer: number
  portionCount: number
  id?: string
}

export const Plate = ({
  portionCount,
  imageUrl,
  layer: plateLayer,
  altText,
  href,
  id,
}: TPlate): ReactElement => {
  const { styles: portionLabelStyles, portionImageWidth } =
    getPortionLabelConfig(portionCount)

  const zIndex = (layers.plates as number) + plateLayer
  const homepageVariant = isBBCGoodFoodSession() ? 'BBCGF ' : null

  return (
    <NextLink key={portionCount} href={href}>
      <PlateBox
        cursor="pointer"
        maxWidth={{
          base: '230px',
          sm: '285px',
          md: '285px',
          lg: '365px',
        }}
        position="relative"
        marginLeft={plateLayer > 0 ? { sm: '-100px', lg: '-150px' } : 0}
        marginTop={plateLayer > 0 ? { base: '-100px', sm: 0 } : 0}
        onClick={() => {
          logEvent({
            category: ANALYTICS_CATEGORIES.homepage,
            action: 'Hero plates clicked',
            label: getGALabel(portionCount),
          })

          trackAnalyticsEvent({
            name: `${homepageVariant || ''}recipe CTA clicked`,
            data: {
              'CTA location': getAmplitudeLabel(portionCount),
            },
          })
        }}
        zIndex={zIndex as ZIndexToken}
      >
        <CMSImage
          loading="eager"
          src={imageUrl}
          alt={altText}
          width={360}
          data-testid={id}
        />
        <Box
          pointerEvents="none"
          position="absolute"
          bottom={{ base: 'unset', sm: '-30px' }}
          {...portionLabelStyles}
        >
          <Image
            data-testid={`homepage-portion-count-${portionCount}-text`}
            loading="eager"
            resolve
            src={`homepage11-2022/hero/${portionCount}person-mobile-1.png`}
            alt={`${portionCount} person`}
            width={portionImageWidth.base}
            sources={[
              {
                src: `homepage11-2022/hero/${portionCount}person-desktop.png`,
                media: minWidths.sm,
                width: portionImageWidth.sm,
              },
            ]}
          />
        </Box>
      </PlateBox>
    </NextLink>
  )
}

function getAmplitudeLabel(portionCount: number): CTALocation {
  const isBBCGoodFood = isBBCGoodFoodSession()
  const origin = isBBCGoodFood ? 'BBC homepage' : 'homepage'
  if (portionCount === 4) {
    return `${origin} family CTA`
  }
  return `${origin} ${portionCount} person CTA` as CTALocation
}

function getGALabel(portionCount: number) {
  if (portionCount === 4) {
    return 'family box'
  }
  return `${portionCount} person box`
}
