import { Flex, Icon, Text } from '@mindfulchefuk/design-system'
import { IconType } from '@mindfulchefuk/design-system/Icon/svgs'
import React, { FunctionComponent } from 'react'

interface Props {
  icon: IconType
  'data-testid'?: string
}

export const RecipeStat: FunctionComponent<Props> = ({
  icon,
  children,
  'data-testid': testId,
}) => (
  <Flex align="center" mr={8} py={8} data-testid={testId}>
    <Icon type={icon} size={20} mr={4} flexShrink={0} />
    <Text variant="body-sm-500-caps">{children}</Text>
  </Flex>
)
