import React, { useRef, useEffect, ReactElement } from 'react'
import { Box } from '@mindfulchefuk/design-system'
import { useHasClientHydrated } from '@mindfulchefuk/hooks/useHasClientHydrated'
import { useTrustPilotClickEvent } from '@mindfulchefuk/features/Homepage/hooks/useTrustPilotClickEvent'

export const TrustPilotMicroStar = ({
  theme,
}: {
  theme: 'light' | 'dark'
}): ReactElement => {
  const isClient = useHasClientHydrated()
  const ref = useRef(null)

  // If window.Trustpilot is available it means that we need to load the
  // TrustPilotSlider from our ref. If it's not, it means the script you pasted
  // into <head /> isn't loaded just yet. When it is, it will automatically load
  // the TrustPilotSlider.
  useEffect(() => {
    if (isClient && window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [isClient])

  useTrustPilotClickEvent({
    widgetName: 'microstar',
    eventAction: 'Trust Pilot Clicked',
    eventLabel: 'Rating clicked',
  })
  return isClient ? (
    <Box
      ref={ref}
      data-widget-name="microstar"
      className="trustpilot-widget"
      data-locale="en-GB"
      data-template-id="5419b732fbfb950b10de65e5"
      data-businessunit-id="563ca4940000ff000585374b"
      data-style-height="24px"
      data-style-width="100%"
      data-theme={theme}
    />
  ) : null
}
