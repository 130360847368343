import React, { ReactElement, useState, useEffect } from 'react'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { Box } from '@mindfulchefuk/design-system'
import { HeroPlateSection } from '@mindfulchefuk/features/Homepage/components/Hero/PlateSection'
import { HeroTopSection } from '@mindfulchefuk/features/Homepage/components/Hero/HeroTopSection'
import { HeroBottomSection } from '@mindfulchefuk/features/Homepage/components/Hero/BottomSection'
import { useCMSHomepageVariant } from '@mindfulchefuk/features/CMS/hooks/useCMSHomepageVariant'
import { expHomepageHero } from '@mindfulchefuk/flagsAndExperiments'
import { HeroAlternative1 } from '@mindfulchefuk/features/Homepage/components/Hero/HeroAlternative1/HeroAlternative1'
import { HeroAlternative2 } from '@mindfulchefuk/features/Homepage/components/Hero/HeroVariant2/HeroAlternative2'
import { detectDevice } from '@mindfulchefuk/utils/detectDevice'

export const Hero = (): ReactElement => {
  const [isMobileDevice, setIsMobileDevice] = useState(false)
  const heroVariant = useFeatureValue(expHomepageHero, 'OFF')

  const {
    heroQuote,
    emphasisInHeroQuote,
    heroTitle,
    heroTitleEmphasis,
    heroByline,
    plates,
  } = useCMSHomepageVariant()

  useEffect(() => {
    const device = detectDevice()
    const isMobile = device === 'iOS' || device === 'Android'

    if (isMobile) {
      setIsMobileDevice(true)
    }
  }, [])
  return (
    <>
      {heroVariant === 'variant-a' && (
        <HeroAlternative1 isMobileDevice={isMobileDevice} />
      )}
      {heroVariant === 'variant-b' && (
        <HeroAlternative2 isMobileDevice={isMobileDevice} />
      )}
      {heroVariant === 'OFF' && (
        <Box as="section" mb={16} id="homepage-hero">
          <HeroTopSection
            heroTitle={heroTitle}
            heroTitleEmphasis={heroTitleEmphasis}
            heroByline={heroByline}
          />

          <HeroPlateSection plates={plates} />

          <Box pt={16} backgroundColor="salt">
            <HeroBottomSection
              ctaText={heroQuote}
              ctaTextEmphasis={emphasisInHeroQuote}
            />
          </Box>
        </Box>
      )}
    </>
  )
}
