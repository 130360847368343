import React, { ReactElement } from 'react'
import { Box, Flex, Text, Image } from '@mindfulchefuk/design-system'

import CTA from '@mindfulchefuk/features/Homepage/components/CTA/CTA'
import {
  ANALYTICS_CATEGORIES,
  IMAGE_PATH_PREFIX,
} from '@mindfulchefuk/constants'

export type THeroAlternative1 = {
  isMobileDevice: boolean
}

export const HeroAlternative1 = ({
  isMobileDevice,
}: THeroAlternative1): ReactElement => {
  const heroImage = !isMobileDevice
    ? `${IMAGE_PATH_PREFIX}homepage/hero-experiment/HeroSpace_Desktop.mp4`
    : `${IMAGE_PATH_PREFIX}homepage/hero-experiment/HeroSpace_Mobile.mp4`

  const trustpilotImage = !isMobileDevice
    ? 'Trustpilot section.png'
    : 'Trustpilot section mobile.png'
  return (
    <>
      <Flex
        flexDirection={{ base: 'column-reverse', md: 'row' }}
        data-testid="hero-alternative-1"
      >
        <Flex
          flexDirection={{ base: 'column-reverse', md: 'column' }}
          alignItems={{ base: 'center', md: 'start' }}
          bg="salt"
          pl={{ base: 40, md: 112 }}
          pr={{ base: 40, md: 0 }}
          pt={{ base: 24, md: 56 }}
          pb={56}
          width={{ md: '50%' }}
        >
          <Box width={{ base: '90%', md: '35%' }} ml={{ base: 40, md: 0 }}>
            <Image
              alt="Mindful chef box with vegetables flowing"
              resolve
              src="homepage/hero-experiment/Purple_Box.png"
              data-testid="overflowing-box-image"
            />
          </Box>
          <Flex
            flexDirection="column"
            alignItems={{ base: 'center', md: 'start' }}
          >
            <Text
              variant={{ base: 'heading-md', md: 'heading-xl' }}
              width={{ md: '450px' }}
              textAlign={{ base: 'center', md: 'start' }}
            >
              94% of customers say Mindful Chef has made healthy eating easier
            </Text>

            <Text
              variant={{ base: 'body-md', md: 'body-xxxl' }}
              pt={{ base: 8, md: 12 }}
              textAlign={{ base: 'center', md: 'start' }}
            >
              Discover the UK&apos;s #1 rated recipe box
            </Text>

            <Flex width={{ md: 144 }} mt={{ base: 28, md: 40 }}>
              <CTA
                analytics={{
                  category: ANALYTICS_CATEGORIES.homepage,
                  action: 'Hero CTA button clicked',
                  label: 'Choose Recipes',
                }}
                showCopy={false}
                buttonSize={{ base: 'small', md: 'medium' }}
              />
            </Flex>

            <Text
              variant={{ base: 'body-sm', md: 'body-lg' }}
              mt={{ base: 12, md: 24 }}
              textAlign={{ base: 'center', md: 'start' }}
            >
              Fully flexible subscription, skip or pause at any time
            </Text>
            <Text
              mt={8}
              variant={{ base: 'body-sm', md: 'body-lg' }}
              mb={{ base: 12, md: 0 }}
            >
              Get 25% off your first 4 recipe boxes
            </Text>
          </Flex>
        </Flex>
        <Box width={{ md: '50%' }} backgroundColor="salt">
          <video
            aria-label="plates of food showing Mindful Chef benefits including no refined carbs and all gluten free recipes. Another claim to show Mindful Chef are a one feeds two partner "
            autoPlay
            muted
            loop
            width="100%"
            height="100%"
            style={{ objectFit: 'cover' }}
          >
            <source
              src={heroImage}
              type="video/mp4"
              data-testid="video-source"
            />
          </video>
        </Box>
      </Flex>
      <Image
        alt="Mindful Chef TrustPilot rating of 4.5 out of 5 with over 19,000 reviews"
        resolve
        src={`homepage/hero-experiment/${trustpilotImage}`}
        data-testid="trustpilot-image"
      />
    </>
  )
}
