import React, { ReactElement } from 'react'
import styled from '@emotion/styled'
import { Box, Flex, Image } from '@mindfulchefuk/design-system'
import { partnerships } from '@mindfulchefuk/features/Homepage/data/partnerships'

const Carousel = styled(Flex)`
  &::-webkit-scrollbar {
    display: none;
  }
`

export type TPartnershipsProps = {
  data?: {
    id: string
    name: string
    image: string
    width: number
    copy: ReactElement
  }[]
}

export const Partnerships = ({
  data = partnerships,
}: TPartnershipsProps): ReactElement => {
  return (
    <Flex
      bg="salt"
      justifyContent="center"
      data-testid="partnerships-section"
      id="homepage-partnerships-section"
    >
      <Carousel
        as="ul"
        py={12}
        ml={{ base: 20, md: 0 }}
        overflowY="hidden"
        overflowX="auto"
        alignItems="start"
      >
        {data.map((p) => (
          <Flex
            key={p.id}
            direction="column"
            minWidth={128}
            maxWidth={128}
            mx={{ base: 6, md: 16 }}
          >
            <Flex height={56} align="center">
              <Box margin="auto">
                <Image
                  resolve
                  src={p.image}
                  alt={p.name}
                  width={p.width}
                  data-testid={`homepage-partnership-${p.name}-image`}
                />
              </Box>
            </Flex>
            <Box mt={10} id={`homepage-partnerships-${p.name}-copy`}>
              {p.copy}
            </Box>
          </Flex>
        ))}
      </Carousel>
    </Flex>
  )
}
