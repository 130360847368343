import { BoxProps, ZIndexToken } from '@mindfulchefuk/design-system'

export const layers = {
  topSection: 1 as ZIndexToken,
  plates: 2 as ZIndexToken,
  bottomSection: 2 as ZIndexToken,
  illustrations: 3 as ZIndexToken,
}

type PortionLabelConfig = {
  styles: BoxProps
  portionImageWidth: {
    base: number
    sm: number
  }
  portionCount: number
}

export const getPortionLabelConfig = (
  portionCount: number
): PortionLabelConfig => {
  return portionCountConfig.find((item) => item.portionCount === portionCount)
}

const portionCountConfig: PortionLabelConfig[] = [
  {
    portionCount: 1,
    styles: {
      top: { base: '10%', sm: 'unset' },
      left: { base: '-10px', sm: '50%' },
      transform: {
        base: 'rotate(25deg)',
        sm: 'translateX(-50%) rotate(0deg)',
      },
    },
    portionImageWidth: {
      base: 24,
      sm: 90,
    },
  },
  {
    portionCount: 2,
    styles: {
      top: { base: '20%', sm: 'unset' },
      left: { base: '-15px', sm: '50%' },
      transform: {
        base: 'rotate(18deg)',
        sm: 'translateX(-50%) rotate(0deg)',
      },
    },
    portionImageWidth: {
      base: 20,
      sm: 90,
    },
  },
  {
    portionCount: 4,
    styles: {
      left: { base: '-15px', sm: '50%' },
      top: { base: '16%', sm: 'unset' },
      transform: {
        base: 'rotate(8deg)',
        sm: 'translateX(-50%) rotate(0deg)',
      },
    },
    portionImageWidth: {
      base: 28,
      sm: 160,
    },
  },
]
