import { useEffect } from 'react'
import { logEvent } from '@mindfulchefuk/utils/analytics'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'

type Props = {
  widgetName: string
  eventAction: string
  eventLabel: string
}

type ActiveElement = {
  src: string
  tagName: string
}

export const useTrustPilotClickEvent = ({
  widgetName,
  eventAction,
  eventLabel,
}: Props) => {
  useEffect(() => {
    const listener = () => {
      // The TrustPilot iframe becomes the activeElement once clicked
      const activeElement = document.activeElement as null as ActiveElement
      if (
        activeElement.tagName === 'IFRAME' &&
        activeElement.src.includes(`widgetName=${widgetName}`)
      ) {
        logEvent({
          category: ANALYTICS_CATEGORIES.homepage,
          action: eventAction,
          label: eventLabel,
        })
      }
    }

    // The blur event fires when a new window is opened
    window.addEventListener('blur', listener)

    return () => window.removeEventListener('blur', listener)
  }, [eventAction, eventLabel, widgetName])
}
