import React, { FunctionComponent } from 'react'
import { Box } from '@mindfulchefuk/design-system'
import { CookieBanner } from '@mindfulchefuk/features/Cookies/components'
import MainLayout from '@mindfulchefuk/layouts/MainLayout'
import { NotificationPromoVoucherExperiment } from '@mindfulchefuk/features/Notifications/components/variants/NotificationPromoVoucherExperiment'
import { FooterProps, HeaderProps } from '@mindfulchefuk/features/Navigation'

export const HomepageLayout: FunctionComponent<{
  footerProps?: FooterProps
  headerProps?: HeaderProps
  showPromoVoucher?: boolean
}> = ({
  children,
  footerProps,
  headerProps = { variant: 'dark' },
  showPromoVoucher = true,
}) => (
  <Box width="100%" minWidth={320} overflowX="hidden" color="aubergine">
    {showPromoVoucher && <NotificationPromoVoucherExperiment />}
    <MainLayout
      title="Recipe Boxes – Fresh UK Healthy Food Box Delivery"
      description="Healthy eating, made easy! Select a few of our delicious healthy recipes and we'll send the ingredients straight to your door. Healthy food, without the hassle!"
      headerProps={headerProps}
      footerProps={footerProps}
    >
      {children}
    </MainLayout>
    <CookieBanner />
  </Box>
)
