import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { RecipeRecommendation } from '@mindfulchefuk/features/Recipes/interfaces'
import {
  GetRecipeRecommendationParams,
  getRecipeRecommendations,
} from '@mindfulchefuk/features/Recipes/services/getRecipeRecommendations'
import isISODate from '@mindfulchefuk/lib/isISODate'
import { STALE_1_HOUR } from '@mindfulchefuk/constants'

export const useGetRecipeRecommendations = <TData = RecipeRecommendation[]>(
  params: GetRecipeRecommendationParams,
  options: UseQueryOptions<RecipeRecommendation[], unknown, TData> = {}
) =>
  useQuery({
    queryKey: ['recipe-recommendations', params],
    queryFn: () => getRecipeRecommendations(params),
    ...options,
    staleTime: STALE_1_HOUR,
    placeholderData: [],
    enabled: !!params.date && !!params.portionCount && isISODate(params.date),
  })
