import React, { ReactElement } from 'react'

import { Box } from '@mindfulchefuk/design-system'

import { BBCGoodFoodLogo } from '@mindfulchefuk/features/Homepage/components/BBCGoodFood/BBCGoodFoodLogo'

import { HeroPlateSection } from '@mindfulchefuk/features/Homepage/components/Hero/PlateSection'
import { HeroTopSection } from '@mindfulchefuk/features/Homepage/components/Hero/HeroTopSection'
import { HeroBottomSection } from '@mindfulchefuk/features/Homepage/components/Hero/BottomSection'
import { useCMSHomepageVariant } from '@mindfulchefuk/features/CMS/hooks/useCMSHomepageVariant'

export const BBCGoodFoodHero = (): ReactElement => {
  const {
    heroQuote,
    emphasisInHeroQuote,
    heroTitle,
    heroTitleEmphasis,
    heroByline,
    plates,
  } = useCMSHomepageVariant()
  return (
    <Box mb={16}>
      <HeroTopSection
        additionalContent={
          <Box maxWidth={{ base: '280px', sm: '384px' }} margin="0 auto">
            <BBCGoodFoodLogo color="white" />
          </Box>
        }
        heroTitle={heroTitle}
        heroTitleEmphasis={heroTitleEmphasis}
        heroByline={heroByline}
      />

      <HeroPlateSection bottomColor="white" plates={plates} />

      <HeroBottomSection
        ctaLocation="BBC homepage hero plates CTA"
        ctaText={heroQuote}
        ctaTextEmphasis={emphasisInHeroQuote}
      />
    </Box>
  )
}
