import React, { ReactElement, FunctionComponent } from 'react'
import {
  Box,
  Container,
  Grid,
  Text,
  Icon,
  Link,
  BoxProps,
} from '@mindfulchefuk/design-system'

import Roundel from '@mindfulchefuk/design-system/Icon/Roundel'
import StackedSmallLogo from '@mindfulchefuk/design-system/Logos/StackedSmall'
import { GoustoLogo } from '@mindfulchefuk/features/Homepage/components/logos/GoustoLogo'
import { HelloFreshLogo } from '@mindfulchefuk/features/Homepage/components/logos/HelloFreshLogo'
import { MindfulChefLogo } from '@mindfulchefuk/design-system/Logos/MindfulChefLogo'

import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'
import { logEvent } from '@mindfulchefuk/utils/analytics'
import { GreenChefLogo } from '@mindfulchefuk/features/Homepage/components/logos/GreenChefLogo'

const COMPARISON_TABLE_SOURCE = process.env.NEXT_PUBLIC_COMPARISON_TABLE_SOURCE

export const ComparisonTable = (): ReactElement => {
  return (
    <Box
      id="homepage-comparison-table-section"
      as="section"
      my={32}
      position="relative"
    >
      <Text
        variant={{ base: 'heading-sm', md: 'heading-md' }}
        as="h1"
        textAlign="center"
        mb={16}
        px={36}
        id="homepage-comparison-table-header"
      >
        We believe the quality of the food (really) matters
      </Text>

      <Text
        variant={{ base: 'body-md', sm: 'body-lg' }}
        as="p"
        textAlign="center"
        mb={32}
        px={36}
        id="homepage-comparison-table-subheading"
      >
        That&apos;s why 66% of our customers have switched from another recipe
        box company
      </Text>

      <Container position="relative" top={{ xl: 24 }} maxWidth="800px">
        <Grid
          templateColumns="2fr 1fr 1fr 1fr 1fr"
          columnGap={[8, 16]}
          alignItems="center"
          justifyContent="center"
        >
          <Box position="relative" left="20%" bottom={14}>
            <WhyUsPointer />
          </Box>
          <StackedSmallLogo
            width="100%"
            mx="auto"
            maxWidth={40}
            display={['inline-block', 'none']}
            id="homepage-mobile-mc-logo"
          />
          <MindfulChefLogo
            width={112}
            mx="auto"
            display={['none', 'inline-block']}
            id="homepage-desktop-mc-logo"
          />
          <HelloFreshLogo id="homepage-comparison-table-hello-fresh-logo" />
          <GoustoLogo
            width={[40, 112]}
            mx="auto"
            id="homepage-comparison-table-gousto-logo"
          />
          <GreenChefLogo
            width={[32, 112]}
            mx="auto"
            id="homepage-comparison-table-green-chef-logo"
          />
        </Grid>
      </Container>
      <Box
        backgroundColor="aubergine"
        pt={32}
        mt={{ base: 0, md: 24 }}
        id="homepage-comparison-table-container"
      >
        <Container maxWidth="800px">
          <Grid
            data-testid="comparison-table-grid"
            templateColumns="2fr 1fr 1fr 1fr 1fr"
            columnGap={[8, 16]}
            rowGap={12}
            alignItems="center"
            justifyContent="center"
            color="white"
            overflow="none"
            maxHeight="none"
          >
            <Row id="homepage-comparison-table-refined-carbs-text">
              No refined carbs like white pasta, bread or white rice
            </Row>
            <Row id="homepage-comparison-table-box-donated-text">
              Every box bought donates one meal to a child in poverty
            </Row>
            <Row id="homepage-comparison-table-gluten-free-text">
              All recipes gluten-free
            </Row>
            <Row gousto id="homepage-comparison-table-1-person-text">
              1-person options
            </Row>
            <Row id="homepage-comparison-table-net-zero-text">
              Committed to Net Zero by 2030
            </Row>
            <Row id="homepage-comparison-table-regenerative-farming-text">
              Investing in regenerative farming practices
            </Row>
            <Box gridColumn={1}>
              <Text variant={{ base: 'body-sm', sm: 'body-lg' }} mb={32}>
                <Link
                  maxWidth={256}
                  textAlign="left"
                  color="white"
                  href={COMPARISON_TABLE_SOURCE}
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                  onClick={() => {
                    logEvent({
                      category: ANALYTICS_CATEGORIES.homepage,
                      action: 'Comparison table clicked',
                      label: `View source link clicked`,
                    })
                  }}
                  id="homepage-comparison-table-source-link-text"
                >
                  Click here for source
                </Link>
              </Text>
            </Box>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

type TRow = {
  children: string
  helloFresh?: boolean
  gousto?: boolean
  greenChef?: boolean
  id?: string
}

const Row: FunctionComponent<TRow> = ({
  children: rowText,
  helloFresh,
  gousto,
  greenChef,
  id,
}) => (
  <>
    <Box gridColumn="1">
      <Text
        maxWidth={256}
        variant={{ base: 'body-sm', sm: 'body-lg' }}
        textAlign="left"
        id={id}
      >
        {rowText}
      </Text>
    </Box>
    <Tick gridColumn={2} />
    {/** MC always has a tick because we're the best */}
    {helloFresh ? <Tick gridColumn={3} /> : <Cross />}
    {gousto ? <Tick gridColumn={4} /> : <Cross />}
    {greenChef ? <Tick gridColumn={5} /> : <Cross />}
  </>
)

const Tick = (props?: BoxProps): ReactElement => (
  <Box py={12} {...props}>
    <Roundel type="tick" background="broccoli" mx="auto" size={[28, 40]} />
  </Box>
)

const Cross = (): ReactElement => (
  <Box py={12}>
    <Roundel
      type="thick-cross"
      background="tomato-300"
      mx="auto"
      size={[28, 40]}
    />
  </Box>
)

const WhyUsPointer = (): ReactElement => (
  <Box textAlign="center" display="inline-block" color="aubergine">
    <Text
      variant={{ base: 'body-md-500', sm: 'body-xxl-500' }}
      mb={4}
      id="homepage-comparison-table-why-us-callout-text"
    >
      Why us?
    </Text>
    <Icon
      mx="auto"
      type="arrow-curved"
      size={32}
      strokeWidth={{ base: '.75px', sm: '1px' }}
      transform="rotate(-90deg)"
    />
  </Box>
)
