import React, { ReactElement } from 'react'
import { Box, Image, Text, Flex } from '@mindfulchefuk/design-system'

import CTA from '@mindfulchefuk/features/Homepage/components/CTA/CTA'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'

export type THeroAlternative2 = {
  isMobileDevice: boolean
}

export const HeroAlternative2 = ({
  isMobileDevice,
}: THeroAlternative2): ReactElement => {
  const headerImage = isMobileDevice
    ? 'homepage/hero-experiment/ExplodingBox_Mobile_700px.png'
    : 'homepage/hero-experiment/ExplodingBox_Desktop_Extended.png'

  return (
    <Box position="relative" data-testid="hero-variant-2">
      <Image src={headerImage} resolve />

      <Flex
        position={{ md: 'absolute' }}
        left={64}
        top={{ md: 28, lg: 40 }}
        bg={{ base: 'salt', md: 'transparent' }}
        px={{ base: 56, md: 0 }}
        py={{ base: 28 }}
        flexDirection="column"
        alignItems={{ base: 'center', md: 'flex-start' }}
        textAlign={{ base: 'center', md: 'left' }}
      >
        <Text
          variant={{ base: 'heading-sm', md: 'heading-md', lg: 'heading-lg' }}
          width={{ base: 288, lg: 384 }}
        >
          94% of customers say Mindful Chef has made healthy eating easier
        </Text>

        <Text
          variant={{ base: 'body-sm', md: 'body-xl', lg: 'body-xxl' }}
          mt={{ base: 8, md: 12 }}
          mb={{ base: 20, lg: 28 }}
          width={{ base: 120, md: 'auto' }}
        >
          Discover the UK&apos;s #1 rated recipe box
        </Text>

        <CTA
          analytics={{
            category: ANALYTICS_CATEGORIES.homepage,
            action: 'Hero CTA button clicked',
            label: 'Choose Recipes',
          }}
          showCopy={false}
          buttonSize={{ base: 'small', md: 'medium' }}
        />

        <Text
          variant={{ base: 'body-sm', lg: 'body-md' }}
          mt={16}
          width={{ base: 160, md: 'auto' }}
        >
          Fully flexible subscription, skip or pause at any time
        </Text>

        <Text
          mt={8}
          variant={{ base: 'body-sm', lg: 'body-md' }}
          width={{ base: 384, md: 'auto' }}
        >
          Get 25% off your first 4 recipe boxes
        </Text>
      </Flex>
      {isMobileDevice && (
        <Image
          alt="Mindful Chef TrustPilot rating of 4.5 out of 5 with over 19,000 reviews"
          resolve
          src="homepage/hero-experiment/Trustpilot section mobile.png"
          data-testid="trustpilot-image"
        />
      )}
    </Box>
  )
}
