import React, { ReactElement } from 'react'
import { Box, Image } from '@mindfulchefuk/design-system'

export const HelloFreshLogo = ({ id }: { id: string }): ReactElement => (
  <Box maxWidth="100%" mx="auto" id={id}>
    <Image
      alt="HelloFresh logo"
      resolve
      src="homepage/HFLogo.png"
      width={112}
    />
  </Box>
)
