import React, { useEffect, useState, ReactElement } from 'react'
import * as CookieConsent from 'vanilla-cookieconsent'

import {
  Box,
  Button,
  Container,
  Flex,
  Text,
  Link,
} from '@mindfulchefuk/design-system'

import routesConfig from '@mindfulchefuk/config/routesConfig'
import { displayDixaMessenger } from '@mindfulchefuk/features/Dixa/displayDixaMessenger'
import { trackAnalyticsEvent } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'

export const CookieBanner = ({
  isNested = false,
}: {
  isNested?: boolean
}): ReactElement => {
  const [isClosed, setIsClosed] = useState(true)

  const handleAccept = () => {
    CookieConsent.acceptCategory('all')
    trackAnalyticsEvent({
      name: 'cookie banner clicked',
      data: {
        'cookie action': 'accept',
      },
    })
    setIsClosed(true)
  }
  const handleRejectNonEssential = () => {
    CookieConsent.acceptCategory([])
    trackAnalyticsEvent({
      name: 'cookie banner clicked',
      data: {
        'cookie action': 'reject',
      },
    })
    setIsClosed(true)
  }
  const trackPolicyClick = () =>
    trackAnalyticsEvent({
      name: 'cookie banner clicked',
      data: {
        'cookie action': 'privacy policy',
      },
    })

  const cookieConfig = CookieConsent.getConfig('cookie')
  useEffect(() => {
    setIsClosed(CookieConsent.validConsent())
  }, [cookieConfig])

  useEffect(() => {
    if (!isClosed) {
      trackAnalyticsEvent({
        name: 'cookie banner shown',
      })
    }
  }, [isClosed])

  if (isClosed) {
    displayDixaMessenger(true)
    return null
  }

  displayDixaMessenger(false)

  return (
    <Box
      bg="broccoli"
      position={isNested ? 'initial' : 'fixed'}
      bottom={0}
      color="white"
      width="100%"
      z="banner"
    >
      <Box data-testid="homepage-cookie-banner">
        <Container>
          <Flex
            align="center"
            justify="space-between"
            py={8}
            style={{ flexWrap: 'wrap', gap: '28px 20px', padding: '24px 0px' }}
          >
            <Text
              as="p"
              variant={['body-md', 'body-xl']}
              style={{
                flexBasis: '400px',
                flexGrow: '1',
              }}
            >
              Let us know if you&apos;re ok with the{' '}
              <Link
                data-testid="cookie-banner-privacy-policy"
                href={`${routesConfig.privacyPolicy.pathname}#cookies`}
                target="_blank"
                rel="noopener noreferrer"
                color="white"
                onClick={trackPolicyClick}
              >
                cookies
              </Link>{' '}
              we use to give you the best online experience.
            </Text>
            <Flex
              style={{
                flexWrap: 'wrap',
                gap: '12px',
                margin: 'auto',
                flexBasis: '360px',
                flexGrow: '2',
              }}
            >
              <Button
                variant="secondary-white"
                data-testid="cookie-banner-accept"
                onClick={handleAccept}
                color="white"
                size={['small', 'large']}
                style={{
                  flexGrow: '1',
                  flexBasis: '220px',
                }}
              >
                Accept Cookies
              </Button>
              <Button
                variant="secondary-white"
                data-testid="cookie-banner-reject"
                onClick={handleRejectNonEssential}
                size={['small', 'large']}
                style={{
                  flexGrow: '1',
                  flexBasis: '220px',
                }}
              >
                Reject Non-Essential
              </Button>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </Box>
  )
}
